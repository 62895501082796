<template>
  <v-container>
    <custom-table
      :loading="loading"
      :title="$t('workspace.title', locale)"
      :headers="[
        { text: $t('workspace.name', locale), value: 'ident' },
        { text: $t('workspace.users', locale), value: 'users' },
        { text: '', value: 'actions' },
      ]"
      :onGetData="handleGetData"
      :tableData="workspaces"
      :onEditItem="handleEdit"
      :onDeleteItem="handleDelete"
      :startIndex="startIndex"
      :itemsPerPage="itemsPerPage"
      :count="count"
    />
    <workspace
      :editID="editID"
      :dialog="clientDialog" :onCloseDialog="() => {this.clientDialog = false}"
      :onGetData="handleGetData"
    />
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import CustomTable from '@/components/customTable/Index'
  import Workspace from './Workspace'
  export default {
    components: { CustomTable, Workspace },
    data: () => ({
      loading: false,
      clientDialog: false,
      editID: null,
      workspaces: [],
      startIndex: 0,
      itemsPerPage: 5,
      count: null
    }),
    computed: {
      ...mapState('app',['locale']),
    },
    mounted () {
      this.handleGetData()
    },
    methods: {
      handleGetData (startIndex) {
        this.loading = true
        if (startIndex >=0 ) this.startIndex = startIndex
        api.getAll ('queue', 'private/workspaces/', this.startIndex, this.itemsPerPage, '')
        .then(response => {             
          this.count = response.count
          this.workspaces = response.data
          this.loading = false
        })
      },
      handleEdit (v) {
        // if new [v] is null else [v] is userID
        this.editID = v
        this.clientDialog = true   
      },
      handleDelete (v) {
        if (v) 
          api.deleteItem ('queue', 'private/workspaces/', v)
          .then(() => {                      
            this.handleGetData(this.startIndex)
          })
      }
    }
  }
</script>
